/* eslint-disable react-hooks/exhaustive-deps */
import FadeIn, { FromDirection } from '@animations/FadeIn';
import { Box, Center, Container } from '@chakra-ui/react';
import { useLayoutAtom } from '@components/core/Layout/atoms/visibility';
import { Card } from '@components/shared/Card';
import { getUserAtom } from '@feature/auth';
import { LoginForm } from '@feature/login';
import { SEO } from '@feature/seo';
import { useAtomValue } from 'jotai';
import { FC, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

export const Head = () => <SEO title='Login' />;

const LoginPage: FC = () => {
  const [ref, inView] = useInView({ threshold: 0.5 });
  const user = useAtomValue(getUserAtom);
  const layout = useLayoutAtom();

  useEffect(() => {
    layout.hide();

    return () => {
      layout.show();
    };
  }, []);

  useEffect(() => {
    if (user) {
      layout.header.show();
    } else {
      layout.hide();
    }
  }, [user]);

  return (
    <Box as='section' height='100vh' ref={ref}>
      <Center h='100%'>
        <Container>
          <FadeIn from={FromDirection.Right} inView={inView}>
            <Card variant='rounded'>
              <LoginForm />
            </Card>
          </FadeIn>
        </Container>
      </Center>
    </Box>
  );
};

export default LoginPage;
