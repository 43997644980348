import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Avatar,
  Box,
  Button,
  CloseButton,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Tooltip,
  useBoolean,
} from '@chakra-ui/react';
import { FormControl } from '@components/shared/FormControl';
import { getUserAtom, useAuth } from '@feature/auth';
import { StaticImage } from 'gatsby-plugin-image';
import { useAtomValue } from 'jotai';
import { FC, useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import EyeClosedIcon from './icons/eye-closed.inline.svg';
import EyeIcon from './icons/eye.inline.svg';

interface FormValues {
  email: string;
  password: string;
}

export const LoginForm: FC = () => {
  const {
    handleSubmit,
    reset,
    register,
    formState: { isSubmitting, errors },
  } = useForm<FormValues>({
    criteriaMode: 'all',
  });
  const { login, logout, isLoading, isTokenExpired, isError } = useAuth();
  const user = useAtomValue(getUserAtom);
  const [hideError, setHideError] = useBoolean(false);
  const [hideTokenExpired, setHideTokenExpired] = useBoolean(false);
  const [showPassword, setShowPassword] = useBoolean(false);
  const [isDisabled, setIsDisabled] = useBoolean(false);

  useEffect(() => {
    if (isLoading || isSubmitting) {
      setIsDisabled.on();
    } else {
      setIsDisabled.off();
    }
  }, [isLoading, isSubmitting, setIsDisabled]);

  const onSubmit: SubmitHandler<FormValues> = async ({ email, password }) => {
    setHideError.off();

    try {
      await login({ email, password });
    } catch {
      reset(undefined, { keepIsSubmitted: false });
    }
  };

  const onLogout = useCallback(() => {
    reset({ email: '', password: '' }, { keepIsSubmitted: false });
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (user) {
    return (
      <>
        <Avatar name={user.name} src={user.profile_url} size='xl' />

        <Heading textAlign='center' marginBottom='2rem'>
          {` 🏴‍☠️ Ahoy, ${user.name}`}
        </Heading>

        <Button width='full' colorScheme='yellow' onClick={onLogout}>
          Logout
        </Button>
      </>
    );
  }

  return (
    <>
      <Heading textAlign='center' marginBottom='2rem'>
        <StaticImage
          src='../../images/logo/logo_80.webp'
          alt='Streetfocus Logo'
          placeholder='blurred'
          height={100}
        />
      </Heading>

      <Box as='form' width='100%' onSubmit={handleSubmit(onSubmit)}>
        <FormControl
          label='E-Mail'
          htmlFor='email'
          error={errors.email}
          disabled={isDisabled}
        >
          <Input
            autoComplete='false'
            id='email'
            type='email'
            {...register('email', {
              required: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'This is not a valid email format',
              },
            })}
          />
        </FormControl>
        <FormControl
          label='Password'
          htmlFor='password'
          error={errors.password}
          disabled={isDisabled}
        >
          <InputGroup size='md'>
            <Input
              id='password'
              type={showPassword ? 'text' : 'password'}
              {...register('password', {
                required: true,
              })}
            />
            <InputRightElement width='4.5rem'>
              <Tooltip label={showPassword ? 'Hide Password' : 'Show Password'}>
                <Button
                  h='1.75rem'
                  size='sm'
                  tabIndex={-1}
                  disabled={isDisabled}
                  onClick={setShowPassword.toggle}
                >
                  {showPassword ? <EyeClosedIcon /> : <EyeIcon />}
                </Button>
              </Tooltip>
            </InputRightElement>
          </InputGroup>
        </FormControl>

        {isError && !hideError && (
          <Alert
            status='error'
            onClick={setHideError.on}
            bg='red.500'
            rounded='8'
          >
            <AlertIcon />
            <AlertTitle mr={2}>Login failed</AlertTitle>
            <AlertDescription>Please check your credentials</AlertDescription>
            <CloseButton position='absolute' right='8px' top='8px' />
          </Alert>
        )}

        {isTokenExpired && !hideTokenExpired && (
          <Alert
            status='info'
            bg='blue.500'
            rounded='8'
            onClick={setHideTokenExpired.on}
          >
            <AlertIcon />
            <AlertTitle mr={2}>Session expired.</AlertTitle>
            <AlertDescription>Please login again.</AlertDescription>
            <CloseButton position='absolute' right='8px' top='8px' />
          </Alert>
        )}

        <Box display='grid' marginTop='3rem' gridGap='1rem'>
          <Button
            width='full'
            isLoading={isSubmitting || isLoading}
            disabled={isDisabled}
            type='submit'
            colorScheme='yellow'
          >
            Login
          </Button>
        </Box>
      </Box>
    </>
  );
};
